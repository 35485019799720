jQuery(document).ready(function($){
    var isItTouchDevice = ('ontouchstart' in document.documentElement);

    // var personalToggler = $('.nav-toggler-personal');
    var sidebarToggler = $('.nav-toggler-sidebar');
    var sidebarClose = $('.nav-toggler-sidebar-close');

    /*personalToggler.on('click',function(e){
        e.preventDefault();

        if(!personalToggler.hasClass('active')){
            $(this).addClass('active');
        }else{
            $(this).removeClass('active');
        }
    });*/

    sidebarToggler.on('click',function(e){
        e.preventDefault();

        if(!sidebarToggler.hasClass('active')){
            $(this).addClass('active');
        }else{
            $(this).removeClass('active');
        }
    });

    sidebarClose.on('click',function(e){
        e.preventDefault();

        if(!sidebarToggler.hasClass('active')){
            sidebarToggler.addClass('active');
        }else{
            sidebarToggler.removeClass('active');
        }
    });



    /** header dropdown js */
    let $headerNavProfilePhoto = $('.navbar-profile-header .navbar-nav .profile-photo');
    $headerNavProfilePhoto.on('click', function (e) {
        if(isItTouchDevice){
            e.preventDefault();

            let $dropdownContentElem = $(this).parents('li').first().find('.dropdown-content');
            if ($dropdownContentElem.length) {
                if ($dropdownContentElem.is(':visible')) {
                    $dropdownContentElem.hide();
                } else {
                    $dropdownContentElem.show();
                }
            }
        }
    });
});